import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";
import React, { useState, useContext, useEffect } from "react";
import { NotificationContext } from "./Notification";
import EmailInput from "./EmailInput";
import LoaderPR from "./LoaderPR";
import { redirectPage } from "./Utils";
import { LogoImage, getQueryParams, handleSessionValidation } from "./Theme";
import SSOService from "./SSOService";

function ForgotPassword() {
  const [user, setUser] = useState("");
  const [showLoading, setLoading] = useState(true);
  const { addToast } = useContext(NotificationContext);
  const queryParams = getQueryParams();
  const service = new SSOService();
  useEffect(() => {
    setLoading(true);
    handleSessionValidation(addToast).then(() => {
      setLoading(false);
    });
  }, [addToast]);
  return (
    <form className="signin-form" name="forgotPassword" autoComplete="on">
      <LogoImage />
      <h2 className="page-header">Forgot Password?</h2>
      <p>
        Please enter your username (email) below, once completed, you will
        receive an email with a confirmation code. This code will be use in the
        following step to reset your password.
      </p>

      <EmailInput name="email" onChange={setUser} />
      <LoaderPR showLoading={showLoading} />
      <div className="d-grid">
        <button
          disabled={showLoading}
          className="btn btn-lg btn-primary"
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            if (user.trim() === "") {
              addToast("Email is required.", "error");
              return;
            }
            setLoading(true);
            handleSessionValidation(addToast).then(() => {
              service
                .forgotPassword(user)
                .then(() => {
                  redirectPage(`/passwordrecovery/${user}${queryParams}`);
                })
                .catch((err) => {
                  setLoading(false);
                  addToast(err.error, "error");
                });
            });
          }}
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export default ForgotPassword;
