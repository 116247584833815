import React, { useState, useContext, useEffect } from "react";
import { encode } from 'js-base64';

import {
  LogoImage,
  getQueryParams,
  getPSI,
  handleSessionValidation,
  getRawDomainFromSearch,
} from "./Theme";
import { redirectPage } from "./Utils";
import LoaderPR from "./LoaderPR";
import { NotificationContext } from "./Notification";
import PasswordInput from "./PasswordInput";
import EmailInput from "./EmailInput";
import SSOService, { serviceUrl } from "./SSOService";

function Login() {
  const domain = getRawDomainFromSearch();
  const id = getPSI();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [showLoading, setLoading] = useState(true);
  const service = new SSOService();
  const queryParams = getQueryParams();
  const { addToast } = useContext(NotificationContext);
  useEffect(() => {
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("force");
    setLoading(true);
    handleSessionValidation(addToast).then(() => {
      setLoading(false);
    });
  }, []);
  return (
    <form className="signin-form" name="loginForm">
      <LogoImage />
      <h2 className="page-header">Login</h2>
      <p>Please enter your credentials below to access the platform.</p>
      <EmailInput name="email" onChange={setUser} />
      <LoaderPR showLoading={showLoading} />
      <PasswordInput
        label="Password"
        name="password"
        autoComplete={true}
        onChange={(newPassword) => setPassword(newPassword)}
      />
      <div className="d-grid">
        <div className="forget-pass">
          <a className="float-end" href={`/forgot${queryParams}`}>Forgot Password?</a>
        </div>
        <button
          disabled={showLoading}
          className="btn btn-lg btn-primary"
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            if (user.trim() === "" || password.trim() === "") {
              addToast("User and password are required.", "error");
              return;
            }
            setLoading(true);
            handleSessionValidation(addToast).then(() => {
              service
                .login(user, password, id, domain)
                .then((data) => {
                  redirectPage(data.url);
                })
                .catch((err) => {
                  if (err.changePassword === true) {
                    const userEncoded = encode(user, true);
                    redirectPage(`/setnewpassword/${userEncoded}${queryParams}`);
                    return;
                  } else if (err.forceChangePassword === true) {
                    window.localStorage.setItem("password", password);
                    window.localStorage.setItem("email", user);
                    window.localStorage.setItem("force", "yes");
                    redirectPage(`/updatepassword${queryParams}`);
                    return;
                  } else if (err.forgotPassword === true) {
                    redirectPage(`/forgot${queryParams}`);
                    return;
                  }
                  if (err.refreshSession === true) {
                    addToast("Your session expired.", "error");
                    redirectPage(
                      `${serviceUrl}/checklogin?returnUrl=${err.returnUrl}`
                    );
                    return;
                  }
                  setLoading(false);
                  addToast(
                    "Credentials are invalid, please contact support.",
                    "error",
                    { height: "60px" }
                  );
                });
            });
          }}
        >
          Sign In
        </button>
      </div>
    </form>
  );
}

export default Login;
